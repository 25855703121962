import React from 'react';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from 'reactstrap';
import {withFormik, Form as FormikForm, Field} from 'formik';
import * as Yup from 'yup';
import deepEqual from 'lodash.isequal';

const ResetPassword = ({
  initialValues,
  values,
  errors,
  touched,
  dirty,
  isSubmitting,
  history,
}) => {
  const hasChanged = deepEqual(values, initialValues);
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className=" mt--8 pb-5" fluid="xl">
        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Reset Password</h2>
                </div>
                <FormikForm autoComplete="off">
                  {touched.currentPassword && errors.currentPassword && (
                    <small className="error-message">
                      {errors.currentPassword}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Current Password*"
                        type="password"
                        name="currentPassword"
                        className="form-control"
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormGroup>

                  {touched.newPassword && errors.newPassword && (
                    <small className="error-message">
                      {errors.newPassword}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="New Password*"
                        type="password"
                        name="newPassword"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>

                  {touched.retypeNewPassword && errors.retypeNewPassword && (
                    <small className="error-message">
                      {errors.retypeNewPassword}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Re-type New Password*"
                        type="password"
                        name="retypeNewPassword"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="my-4"></Row>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      type="submit"
                      color="primary"
                      disabled={hasChanged || hasErrors || isSubmitting}
                    >
                      {isSubmitting ? (
                        <span>
                          <i className="fas fa-spinner fa-spin"></i>Please
                          wait...
                        </span>
                      ) : (
                        'Reset Password'
                      )}
                    </Button>
                  </div>
                </FormikForm>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Container>
    </>
  );
};

const AdminResetPassword = withFormik({
  mapPropsToValues({currentPassword, newPassword, retypeNewPassword}) {
    return {
      currentPassword: '',
      newPassword: '',
      retypeNewPassword: '',
    };
  },
  validationSchema: Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .notOneOf(
        [Yup.ref('currentPassword'), null],
        'Old and new password must not be the same'
      ),
    retypeNewPassword: Yup.string()
      .required('Please retype your new Password')
      .oneOf([Yup.ref('newPassword'), null], 'New passwords must match'),
  }),
  async handleSubmit(values, {resetForm, setErrors, setSubmitting, props}) {
    try {
      const token = sessionStorage.getItem('token');

      const passcode = CryptoJS.HmacSHA512(
        token,
        process.env.REACT_APP_HASH_STRING
      ).toString();

      const data = {
        token,
        action: 'change_password',
        current_password: values.currentPassword,
        new_password: values.newPassword,
        passcode,
      };
      const baseUrl = process.env.REACT_APP_BASEURL;
      const response = await fetch(`${baseUrl}/startimes/change_password.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (responseData.status === '0') {
        console.log(props);
        sessionStorage.clear();
        resetForm();
        setSubmitting(false);
        props.history.push('/');
        return Swal.fire({
          icon: 'info',
          text: 'Password successfully changed',
        });
      } else {
        return Swal.fire({
          icon: 'info',
          text: responseData.message,
        });
      }
    } catch (err) {
      return Swal.fire({
        icon: 'error',
        title: 'Password Reset Failed',
        text: 'Unable to reset Password, Please check your internet connection',
      });
    }
  },
})(ResetPassword);

export default AdminResetPassword;
