import GetTransactions from './views/pages/GetTransactions';
import AdminResetPassword from 'views/pages/ResetPassword.jsx';

var routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: GetTransactions,
    layout: '/admin',
  },
  {
    path: '/reset-password',
    name: 'Change Password',
    icon: 'ni ni-lock-circle-open',
    component: AdminResetPassword,
    layout: '/admin',
  },
];
export default routes;
