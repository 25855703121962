import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
// reactstrap components
import {Container} from 'reactstrap';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import AdminFooter from 'components/Footers/AdminFooter.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import routes from 'routes.js';

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return <Redirect to="/auth/login" />;
      }
    });
  };
  // getBrandText = path => {
  //   for (let i = 0; i < routes.length; i++) {

  //     if (
  //       this.props.location.pathname.indexOf(
  //         routes[i].layout + routes[i].path
  //       ) !== -1
  //     ) {
  //       return routes[i].name;
  //     }
  //     // else if (
  //     //   this.props.location.pathname.indexOf(
  //     //     routes[i].layout + routes[i].path
  //     //   ) === -1
  //     // ) {
  //     // return routes[i].heading;
  //     // }
  //   }
  //   return "Dashbord";
  // };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/admin/index',
            imgSrc: require('assets/img/brand/startimes-logo.png'),
            imgAlt: 'Startimes Logo',
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} />
          <Switch>
            {this.getRoutes(routes)}

            <Route path="*">
              <Redirect to="/notfound" />
            </Route>
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
