import React from 'react';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from 'reactstrap';
import './login.css';

class Login extends React.Component {
  state = {
    username: '',
    password: '',
    isLoading: false,
  };

  handleChange = (e) => {
    const {name, value} = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleLogin = async (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });

    try {
      const hashString = process.env.REACT_APP_HASH_STRING;
      const {username, password} = this.state;
      const key = CryptoJS.HmacSHA512(username, hashString).toString();

      sessionStorage.setItem('passcode', key);
      const baseUrl = process.env.REACT_APP_BASEURL;
      const data = {
        username: username,
        action: 'login',
        password: password,
        passcode: key,
      };
      console.log(process.env.REACT_APP_BASEURL);
      const response = await fetch(`${baseUrl}/startimes/login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();

      if (jsonResponse.status === '0') {
        console.log(jsonResponse);
        //save session data
        localStorage.setItem('userData', JSON.stringify(jsonResponse));
        sessionStorage.setItem('token', jsonResponse.token);
        sessionStorage.setItem('company_name', jsonResponse.company_name);
        sessionStorage.setItem('company_logo', jsonResponse.company_logo);
        sessionStorage.setItem('isLoggedIn', true);
        Swal.fire({
          icon: 'success',
          text: 'Login successful',
          position: 'top-end',
          showClass: {
            popup: 'animated fadeInDown faster',
          },
          hideClass: {
            popup: 'animated fadeOutUp faster',
          },
        });
        this.setState({
          isLoading: false,
        });
        this.props.history.push('/admin/Index');
      } else {
        Swal.fire({
          icon: 'error',
          text: jsonResponse.message,
          position: 'top-end',
        });
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Unable to connect',
        text: 'Please check your internet connection and try again',
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const {isLoading} = this.state;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      name="username"
                      placeholder="username"
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div>
                  <Spinner color="primary" />
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={this.handleLogin}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i>Please wait...
                      </span>
                    ) : (
                      'Sign In'
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
