import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
// const Excel = ReactExport.Excel;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DataModal = (props) => {
  const {
    buttonLabel,
    className,
    modal,
    transactionResponse,
    toggle,
    transactions,
    headers,
    startDate,
    endDate,
  } = props;

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader size="lg" toggle={toggle}>
          {`Transaction Reconciliation from ${startDate} to ${endDate}`}
        </ModalHeader>
        <ModalBody>
          <ListGroup>
            <ListGroupItem disabled tag="a" href="#">
              Messase : {transactionResponse.message}
            </ListGroupItem>
            <ListGroupItem disabled tag="a" href="#">
              Total Amount : {transactionResponse.total_amount}
            </ListGroupItem>
            <ListGroupItem disabled tag="a" href="#">
              Total Count : {transactionResponse.total_count}
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          {/* <Excel filename="Transactions"> */}
          <ExcelFile
            filename={`Transaction Reconcilliation from ${startDate} to ${endDate}`}
            element={<Button color="primary">Download xlsx File</Button>}
          >
            <ExcelSheet data={transactions} name="Transactions Reconciliation">
              {headers.map((item) => (
                <ExcelColumn
                  key={item.key}
                  label={item.label}
                  value={item.key}
                />
              ))}
            </ExcelSheet>
          </ExcelFile>
          {/* </Excel> */}
          <CSVLink
            data={transactions}
            headers={headers}
            filename={"Transaction-Reconciliation.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            Download CSV file
          </CSVLink>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DataModal;
