
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import PageNotFound from './views/pages/PageNotFound';

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Redirect exact from="/" to="/auth/login" />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/notfound" render={props => <PageNotFound {...props} />} />
      <Route path="*">
        <Redirect to="/notfound" />
      </Route>
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
