import React, { useState } from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import DataModal from "./DataModal";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Label,
  Input,
  Alert,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { withFormik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";
import deepEqual from "lodash.isequal";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [transactionResponse, setTransactionResponse] = useState({});
  const [modal, setModal] = useState(false);
  const today = new Date();
  const toggle = () => setModal(!modal);

  const handleStartChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndChange = (event) => {
    setEndDate(event.target.value);
  };
  const headers = [
    { label: "TRANSACTION", key: "transaction_number" },
    { label: "ORDER", key: "order_number" },
    { label: "TRANSACTION AMOUNT", key: "transaction_amount" },
    { label: "CUSTOMER ID", key: "customer_id" },
    { label: "SMART CARD", key: "smart_card" },
    { label: "TRANSACTION TIME", key: "transaction_time" },
    { label: "PHONE NUMBER", key: "phone_number" },
    { label: "REMARKS", key: "remark" },
  ];
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const token = sessionStorage.getItem("token");

      const passcode = CryptoJS.HmacSHA512(
        token,
        process.env.REACT_APP_HASH_STRING
      ).toString();

      const data = {
        token,
        action: "get_transactions",
        start_date: startDate,
        end_date: endDate,
        passcode,
      };
      const baseUrl = process.env.REACT_APP_BASEURL;
      const response = await fetch(
        `${baseUrl}/startimes/get_transactions.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();

      setIsSubmitting(false);
      if (responseData.status === "0") {
        setTransactions(responseData.transactions);
        setTransactionResponse(responseData);

        setModal(true);
      } else {
        return Swal.fire({
          icon: "info",
          text: responseData.message,
        });
      }
    } catch (err) {
      setIsSubmitting(false);
      return Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: "Get Transactions failed, Please check your internet connection",
      });
    }
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className=" mt--8 pb-5" fluid="xl">
        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Get Transactions</h2>
                  {new Date(startDate) &&
                  new Date(endDate) &&
                  new Date(startDate) > new Date(endDate) ? (
                    <Alert color="danger">
                      START DATE must not be later than END DATE
                    </Alert>
                  ) : null}
                </div>
                <form autoComplete="off">
                  <Label for="startDate">Start Date</Label>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-data" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        type="date"
                        name="startDate"
                        placeholder="Start Date"
                        value={startDate}
                        onChange={handleStartChange}
                      />
                    </InputGroup>
                    {new Date(startDate) > today ? (
                      <p style={{ color: "red" }}>
                        Start Date must not be later than today
                      </p>
                    ) : null}
                  </FormGroup>
                  <Label for="startDate">End Date</Label>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-date"></i>
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        type="date"
                        name="endDate"
                        placeholder="End Date"
                        value={endDate}
                        onChange={handleEndChange}
                      />
                    </InputGroup>
                    {new Date(endDate) > today ? (
                      <p style={{ color: "red" }}>
                        End Date must not be later than today
                      </p>
                    ) : null}
                  </FormGroup>
                  <Row className="my-4"></Row>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      type="submit"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={
                        !startDate ||
                        !endDate ||
                        new Date(startDate) > today ||
                        new Date(endDate) > today ||
                        new Date(startDate) > new Date(endDate)
                      }
                    >
                      {isSubmitting ? (
                        <span>
                          <i className="fas fa-spinner fa-spin"></i>Please
                          wait...
                        </span>
                      ) : (
                        "Get Transactions"
                      )}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Container>
      <DataModal
        modal={modal}
        transactionResponse={transactionResponse}
        toggle={toggle}
        transactions={transactions}
        headers={headers}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
};

export default Transactions;
